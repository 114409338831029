import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {FuseModule} from '@fuse';
import {FuseConfigModule} from '@fuse/services/config';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {mockApiServices} from 'app/mock-api';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
import {EditorModule} from '@tinymce/tinymce-angular';
import {DirectivesModule} from '@portal/shared/directives/directives.modules';
import {MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS} from '@angular-material-components/color-picker';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {FuseAlertModule} from '@portal/fuse/components/alert';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedMatTableModule} from '@portal/shared/shared-mat-table.module';
import {MatPortuguesePaginatorService} from "@portal/shared/services/mat-portuguese-paginator.service";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {
    EventProductsCalculatorComponent
} from "./modules/admin/party/events/event-details/event-products/event-products-calculator/event-products-calculator.component";
import {ComponentsModule} from "./modules/admin/shared/components/components.module";
import {QuillModule} from "ngx-quill";
import Quill from 'quill';
import { DemoComponent } from './modules/landing/demo/demo.component';

registerLocaleData(localePt, 'pt-PT');

let SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['12px', '14px', '16px', '18px', '20px', '24px', '32px'];
Quill.register(SizeStyle, true);

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
        EventProductsCalculatorComponent,
        DemoComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        EditorModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        DirectivesModule,
        FuseAlertModule,
        ReactiveFormsModule,
        SharedMatTableModule,
        ComponentsModule,
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'], // botões de formatação básica
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }], // listas
                    [{ 'align': [] }], // alinhamento
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'size': SizeStyle.whitelist }],
                ]
            },
            theme: 'snow', // tema padrão
            placeholder: 'Digite o texto aqui...', // placeholder padrão para todos os editores
        }),
    ],
    exports: [],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
        { provide: MatPaginatorIntl, useClass: MatPortuguesePaginatorService },
        { provide: LOCALE_ID, useValue: 'pt-PT' },
    ]
})
export class AppModule {
}
