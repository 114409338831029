export enum UsersPartyPermissionsWriteType {
    IsOwner = 1,
    DonationWrite = Math.pow(2, 1),
    GamesWrite = Math.pow(2, 2),
    SponsorsWrite = Math.pow(2, 3),
    EventsWrite = Math.pow(2, 4),
    ExpensesWrite = Math.pow(2, 5),
    PartyNotesWrite = Math.pow(2, 6),
    RoadmapWrite = Math.pow(2, 7),
    RevenuesWrite= Math.pow(2, 8),
    RafflesWrite = Math.pow(2, 9), // 512
    DashboardWrite = Math.pow(2, 10), // 1024
    MembersWrite = Math.pow(2, 11), // 2048
    StandsWrite = Math.pow(2, 12), // 4096
    BarWrite = Math.pow(2, 13), // 8192
}

// Máximo é Math.pow(2, 31)
